import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import { encode } from "js-base64";

import Header from "../../components/headers/header";
import Ariane from "../../components/ariane";
import Footer from "../../components/footers/footer";
import FormExpert from "../../components/forms/formExpert";

import Reassurance from "../../components/reassurance";

////Intégration Shortcodes
import CTAExpertPresChezVous from "../../components/shortcodes/CTAExpertPresChezVous";
import CTAExpertAvisIndependant from "../../components/shortcodes/CTAExpertAvisIndependant";
import Image from "../../components/shortcodes/Image";
import Youtube from "../../components/shortcodes/youtube";

// CTA Expert
import CTAOpengroupe from "../../components/shortcodes/cta/CTAOpengroupe";
import CTAEdieuxExpertise from "../../components/shortcodes/cta/CTAEdieuxExpertise";
import CTADuboisExpertise from "../../components/shortcodes/cta/CTADuboisExpertise";
import CTACabinetE2PPloixExpertise from "../../components/shortcodes/cta/CTACabinetE2PPloixExpertise";
import CTALunoaExpertConseilBenamar from "../../components/shortcodes/cta/CTALunoaExpertConseilBenamar";
import CTAAvayahJeromeGallaud from "../../components/shortcodes/cta/CTAAvayahJeromeGallaud";
import CTACabinetLespagnol from "../../components/shortcodes/cta/CTACabinetLespagnol";
import CTAEGExpabatRoux from "../../components/shortcodes/cta/CTAEGExpabatRoux";
import CTAVincentMoreau from "../../components/shortcodes/cta/CTAVincentMoreau";
import CTABatExpert34 from "../../components/shortcodes/cta/CTABatExpert34";
import CTABenoitToussaint from "../../components/shortcodes/cta/CTABenoitToussaint";
import CTA1GSolutions from "../../components/shortcodes/cta/CTA1GSolutions";
import CTA2RExpertise from "../../components/shortcodes/cta/CTA2RExpertise";

const shortcodes = {
  Youtube,
  Image,
  CTAOpengroupe,
  CTAExpertPresChezVous,
  CTAExpertAvisIndependant,
  CTAEdieuxExpertise,
  CTADuboisExpertise,
  CTACabinetE2PPloixExpertise,
  CTALunoaExpertConseilBenamar,
  CTAAvayahJeromeGallaud,
  CTACabinetLespagnol,
  CTAEGExpabatRoux,
  CTAVincentMoreau,
  CTABatExpert34,
  CTABenoitToussaint,
  CTA1GSolutions,
  CTA2RExpertise,
};
////

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode("emilie.roux@lesexpertsvauban.org");
  const moyen = "Formulaire Fissuration Geo Emilie-Roux";

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>
      <div class="relative max-w-7xl mx-auto shadow-lg lg:shadow-none lg:flex mb-4">
        <Ariane fil_ariane={fil_ariane} />
      </div>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <div class="flex flex-1 overflow-hidden">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                <div className="relative py-4 bg-white overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                      <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>
                      <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                        {excerpt}
                      </p>

                      <GatsbyImage
                        className="rounded-lg shadow-lg"
                        alt={thumbnailText}
                        image={getImage(embeddedImagesLocal[0])}
                      />
                    </div>

                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start col-span-1 p-4 lg:py-8 lg:w-1/3">
          <div className="bg-white ">
            <div className="rounded-lg bg-white shadow-lg mb-8 flex flex-col flex-grow text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8 border">
              <div className="text-center mb-4 text-xl font-bold">
                <p>Votre Expert Bâtiment proche de chez vous</p>
              </div>
              <a href="/expert-fissures/eg-expabat-emilie-roux/">
                <div className="mx-auto h-40 w-40 rounded-full">
                  <StaticImage
                    className="mx-auto h-40 w-40 rounded-full"
                    src="../../markdown-pages/experts/eg-expabat-emilie-roux/Emilie-Roux.jpg"
                    alt=""
                  />
                </div>
                <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">
                  EG EXPABAT
                </h3>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  <strong>Émilie Roux</strong>
                  <br />
                  15 Allée des Charpentiers
                  <br />
                  77181 COURTRY
                  <br />
                  06 24 06 82 64
                </p>
              </a>

              <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
            </div>
          </div>

          <Reassurance />
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
